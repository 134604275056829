import React from 'react'

function SServices() {
  return (
    <div className='d-flex justify-content-center mt-5'>

        <h1 style={{fontFamily: 'Dancing Script'}}>The page is under development and will be released soon, keep in touch to stay updated!!!</h1>
    </div>
  )
}

export default SServices